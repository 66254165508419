var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1200" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "v-modal-fixed style-2" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline d-flex justify-space-between px-5 py-3 v-modal-header"
            },
            [
              _c("h5", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("addKeys.addKeywords")))
              ]),
              _c(
                "v-icon",
                {
                  staticClass: "black--text",
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("mdi-close")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-4 pb-4" },
            [
              _c(
                "v-container",
                { staticClass: "stepper-template pa-0", attrs: { fluid: "" } },
                [
                  [
                    _c(
                      "div",
                      { staticClass: "my-2" },
                      [
                        _c("v-row", [
                          _c(
                            "div",
                            { staticClass: "logo d-flex align-center mb-5" },
                            [
                              _c("v-img", {
                                staticClass: "rounded-lg",
                                attrs: {
                                  color: "secondary",
                                  "lazy-src": _vm.selectedAppIcon,
                                  "max-width": "60",
                                  "max-height": "60",
                                  "min-width": "20",
                                  "min-height": "20",
                                  src: _vm.selectedAppIcon
                                }
                              }),
                              _c("v-divider", {
                                staticClass: "ml-13 mr-8",
                                attrs: { vertical: "" }
                              }),
                              _c("div", { staticClass: "logo-content" }, [
                                _c("p", { staticClass: "app-name mb-0" }, [
                                  _c("strong", [_vm._v(_vm._s(_vm.appName))])
                                ]),
                                _c("span", { staticClass: "developer-name" }, [
                                  _vm._v(_vm._s(_vm.selectedAppDev))
                                ])
                              ])
                            ],
                            1
                          )
                        ]),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { outlined: "", height: "100%" } },
                                  [
                                    _c("div", { staticClass: "d-flex pa-2" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "addKeys.keywordSuggestionsFor"
                                            )
                                          ) +
                                            " " +
                                            _vm._s(_vm.appName) +
                                            " " +
                                            _vm._s(_vm.$t("addKeys.on")) +
                                            " " +
                                            _vm._s(_vm.countryName)
                                        )
                                      ])
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "keyword-table" },
                                      [
                                        _c(
                                          "v-card",
                                          [
                                            _c(
                                              "v-card-title",
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "append-icon":
                                                      "mdi-magnify",
                                                    label: "Search here",
                                                    outlined: "",
                                                    dense: "",
                                                    "single-line": "",
                                                    "hide-details": ""
                                                  },
                                                  model: {
                                                    value: _vm.searchedKeyword,
                                                    callback: function($$v) {
                                                      _vm.searchedKeyword = $$v
                                                    },
                                                    expression:
                                                      "searchedKeyword"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c("v-data-table", {
                                              staticClass: "v-max-height-300",
                                              attrs: {
                                                headers: _vm.recomendHeaders,
                                                items: _vm.recomendItems,
                                                search: _vm.searchedKeyword,
                                                loading: _vm.loadingRecomends,
                                                "loading-text":
                                                  "Loading... Please wait"
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "item.addKeywordPlus",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "mx-2 pa-0",
                                                          attrs: {
                                                            width: "22",
                                                            height: "18",
                                                            "min-width": "20",
                                                            text: "",
                                                            depressed: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.addKeywordPlusClicked(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "success--text"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-plus-circle-outline"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "6" } },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { outlined: "", height: "100%" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center justify-space-between"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex pa-2" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "addKeys.addedKeywords"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "pagination pr-2" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "pagination-total secondary--text"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("addKeys.total")
                                                  ) + " "
                                                ),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(_vm.keywords.length)
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "pa-2 rounded-0 border-x-0 no-border-bottom",
                                        attrs: { outlined: "", height: "58" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "addkeword" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c("v-text-field", {
                                                  staticClass:
                                                    "rounded-0 rounded-l-lg no-field-details mb-0",
                                                  attrs: {
                                                    required: "",
                                                    outlined: "",
                                                    dense: ""
                                                  },
                                                  model: {
                                                    value: _vm.keywordInput,
                                                    callback: function($$v) {
                                                      _vm.keywordInput = $$v
                                                    },
                                                    expression: "keywordInput"
                                                  }
                                                }),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "rounded-0 rounded-r-lg border-l-0 grey--text",
                                                    attrs: {
                                                      width: "36",
                                                      height: "40",
                                                      depressed: "",
                                                      outlined: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.addKeyword()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "secondary--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "addKeys.add"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "keword-lists pa-2 v-max-height-300"
                                      },
                                      _vm._l(_vm.keywords, function(keyword) {
                                        return _c(
                                          "v-row",
                                          {
                                            key: keyword.text,
                                            staticClass: "mb-2"
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "py-0 pl-0 pt-3 word-nowrap",
                                                attrs: { cols: "12", sm: "4" }
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      width: "32",
                                                      depressed: "",
                                                      "x-small": "",
                                                      fab: "",
                                                      text: "",
                                                      color: "error"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteKeyword(
                                                          keyword.text
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          "mdi-delete-outline"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(keyword.text) +
                                                    " "
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "py-0 pt-3",
                                                attrs: { cols: "12", sm: "4" }
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass:
                                                    "no-field-details",
                                                  attrs: {
                                                    items: _vm.matchTypes,
                                                    label: "Match Type",
                                                    required: "",
                                                    outlined: "",
                                                    dense: ""
                                                  },
                                                  model: {
                                                    value: keyword.matchType,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        keyword,
                                                        "matchType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "keyword.matchType"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "py-0 pt-3 pr-0",
                                                attrs: { cols: "12", sm: "4" }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "field" },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "no-field-details",
                                                      attrs: {
                                                        type: "text",
                                                        required: "",
                                                        outlined: "",
                                                        dense: "",
                                                        "prepend-inner-icon":
                                                          "mdi-currency-usd"
                                                      },
                                                      model: {
                                                        value:
                                                          keyword.bidAmount
                                                            .amount,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            keyword.bidAmount,
                                                            "amount",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "keyword.bidAmount.amount"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-7 pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "grey",
                    depressed: "",
                    outlined: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("addKeys.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { width: "127", color: "success", depressed: "" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("addKeys.apply")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }