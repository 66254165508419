import { IAdGroup, ICampaignReq, ISuccess } from '@shared/interfaces';
import { joinUrl, replaceParamsWithValues } from '@shared/utils';

import { API_BASE_URL } from '@config';
import { CAMPAIGN_ROUTES, ORG_HEADER_NAME } from '@shared/config';
import { HttpService } from '@core/services';

/**
 * @class CampaignsService
 * @description service class for handling campaigns related operations.
 */
export class CampaignsService {
  private static get url() {
    return joinUrl(API_BASE_URL as string, CAMPAIGN_ROUTES.noVbase);
  }

  private static get adGroupUrl() {
    return joinUrl(API_BASE_URL as string, CAMPAIGN_ROUTES.noVbase);
  }

  /**
   * @function
   * @name create
   * @description launches a post request for creating a campaign.
   * @param {ICampaignReq} campaignReq campaign details
   * @returns {Promise<ISuccess>} success response.
   */
  public static async create(campaignReq: ICampaignReq): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.url, CAMPAIGN_ROUTES.create), campaignReq);
    return response;
  }

  /**
   * @function
   * @name createAdGroup
   * @description launches a post request for creating an ad group.
   * @param {IAdGroup} AdGroupReq campaign details
   * @returns {Promise<ISuccess>} success response.
   */
  public static async createAdGroup(adGroupReq: IAdGroup): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.adGroupUrl, CAMPAIGN_ROUTES.createAdroup), adGroupReq);
    return response;
  }

  public static async searchApp(orgId: number, appName: string): Promise<ISuccess> {
    const response = await HttpService.get<ISuccess>(joinUrl(this.adGroupUrl, replaceParamsWithValues(CAMPAIGN_ROUTES.searchApp, orgId.toString(), appName)), { headers: { [ORG_HEADER_NAME]: orgId }});
    return response;
  }

  public static async searchedLocations(orgId: number, locationName: string, countrycode : string): Promise<ISuccess> {
    const params = '?locationName='+locationName+'&countrycode='+countrycode;
    const response = await HttpService.get<ISuccess>(joinUrl(this.adGroupUrl, replaceParamsWithValues(CAMPAIGN_ROUTES.locations, orgId), params), { headers: { [ORG_HEADER_NAME]: orgId }});
    return response;
  }

  public static async creativeappassets(orgId: number, adamId: string, countrycodes : string[]): Promise<ISuccess> {
    const params = '?adamId='+adamId;
    const response = await HttpService.post<ISuccess>(joinUrl(this.adGroupUrl, replaceParamsWithValues(CAMPAIGN_ROUTES.creativeappassets, orgId.toString()), params), countrycodes, { headers: { [ORG_HEADER_NAME]: orgId }});
    return response;
  }
}
