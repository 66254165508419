















































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ICreativeSet } from '@shared/interfaces';
import { CampaignsService } from '@services/campaigns.service';
import Vue from 'vue';

export default Vue.extend({
  name: 'AddCreativeSet',
  props: {
    appDetails: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  watch: {
    appDetails: async function (data) {
      this.show = true;
      this.fullscreenLoader = this.$loading.show();

      try {
        this.creativeappassets = await CampaignsService.creativeappassets(
          data.orgId,
          data.adamId,
          data.countries
        );
      } catch {
        this.fullscreenLoader.hide();
        return this.$notify.error(
          'Unable to fetch asset details. Please try again!'
        );
      }
      this.csName = 'cs-' + Date.now();
      this.languageDisplayNames = [];
      for (const langKey in this.creativeappassets.creativeSetDetails) {
        this.languageDisplayNames.push({
          key: langKey,
          languageDisplayName: this.creativeappassets.creativeSetDetails[
            langKey
          ].languageDisplayName,
        });
      }
      this.fullscreenLoader.hide();
    },
  },
  data() {
    return {
      fullscreenLoader: null,
      valid: null,
      show: false,
      csName: '',
      allImgs: {},
      selectedImgs: [],
      defaultGenIds: [],
      languageDisplayNames: [],
      selectedLanguage: '',
      creativeappassets: null,
      appPreviewDeviceWithAssets: Object.create(null),
    };
  },

  computed: {
    isAssestsSelectionValid: {
      cache: false,
      get: function () {
        for (const key in this.appPreviewDeviceWithAssets) {
          if (
            this.appPreviewDeviceWithAssets[key].ipadError ||
            this.appPreviewDeviceWithAssets[key].iphoneError
          ) {
            return false;
          }
        }

        return true;
      },
    },
  },

  methods: {
    validateAssets(device, deviceAssets) {
      const isIPad = device.includes('ipad');

      const minAllowedPortraits = isIPad ? 2 : 3;

      const landscapesSelected = deviceAssets.screenshots.filter(
        (screenshot) =>
          this.selectedImgs.includes(screenshot.assetGenId) &&
          screenshot.assetType === 'SCREENSHOT' &&
          screenshot.orientation === 'LANDSCAPE'
      ).length;
      const portraitsSelected = deviceAssets.screenshots.filter(
        (screenshot) =>
          this.selectedImgs.includes(screenshot.assetGenId) &&
          screenshot.assetType === 'SCREENSHOT' &&
          screenshot.orientation === 'PORTRAIT'
      ).length;

      // if one landscape is selected, or nothing is selected
      if (landscapesSelected || (!portraitsSelected && !landscapesSelected)) {
        deviceAssets.ipadError = undefined;
        deviceAssets.iphoneError = undefined;
        return;
      }

      if (minAllowedPortraits > portraitsSelected) {
        isIPad
          ? (deviceAssets.ipadError = true)
          : (deviceAssets.iphoneError = true);
      } else {
        deviceAssets.ipadError = undefined;
        deviceAssets.iphoneError = undefined;
      }
    },
    close() {
      this.show = false;
      this.csName = '';
      this.allImgs = {};
      this.selectedImgs = [];
      this.defaultGenIds = [];
      this.languageDisplayNames = [];
      this.selectedLanguage = '';
      this.creativeappassets = null;
      this.appPreviewDeviceWithAssets = Object.create(null);
    },

    onLanguageSelection() {
      const enUs = this.creativeappassets.creativeSetDetails[
        this.selectedLanguage
      ];

      this.appPreviewDeviceWithAssets = enUs.appPreviewDeviceWithAssets;
    },

    creativeSetObject() {
      const creativeSet: ICreativeSet = {
        adamId: Number(this.appDetails.adamId),
        name: this.csName,
        languageCode: this.selectedLanguage,
        assetsGenIds: this.selectedImgs,
      };

      this.$emit('onSaveCreativeSet', {
        creativeSet,
        orgId: this.appDetails.orgId,
      });

      this.close();
    },
  },
});
